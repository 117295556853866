import React from 'react';
import Laptop2 from '../assets/laptop2.png';

const List = () => {
    return (
        <div className='w-full bg-white px-4'>
            <div className='max-w-[1240px] mx-auto flex flex-col md:flex-row items-center justify-center'>
                <div className='md:w-1/2 text-left md:text-left md:pr-8'>
                    <h2 className="mb-2 font-bold text-2xl md:text-4xl pb-5">O que você aprenderá:</h2>
                    <ul className="mx-auto space-y-1 list-disc list-inside text-dark-900">
                        <li>Segredos para aumentar sua lucratividade na advocacia</li>
                        <li>Como atrair clientes de alto valor</li>
                        <li>Estratégias para precificar seus serviços de forma competitiva</li>
                        <li>Táticas eficazes para enfrentar o processo de crescimento</li>
                        <li>E muito mais!</li>
                    </ul>
                </div>
                <img className='w-[500px] justify-center align-middle' src={Laptop2} alt='Laptop' />
            </div>
        </div>
    )
}

export default List;