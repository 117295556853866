import { useRouteError } from "react-router-dom";
import { Link } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="bg-black text-white flex flex-col mx-auto text-center py-10 gap-4" id="error-page">
      <h1>Oops!</h1>
      <p>Um erro ocorreu! Volta para a página inicial</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      <Link to={'/'} className="bg-white text-[#000] w-[200px] rounded-md font-bold text-lg my-6 mx-auto px-6 py-3">Página Inicial</Link>
    </div>
  );
}