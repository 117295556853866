import React from 'react';

const MapWithInfoComponent = () => {
  return (
    <div className="w-full px-4 py-16 flex flex-col md:flex-row text-white" id='informações'>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
            <div className="p-4">
            <h2 className="text-2xl md:text-4xl font-bold mb-4">Informações do Evento:</h2>
            <p className="mb-2"><strong>Mentoria:</strong> Conquiste seus Primeiros 50 Mil Reais como Advogado(a)</p>
            <p className="mb-2"><strong>Local:</strong> Auditório do Iguatemi Business em Sorocaba, das 8h ás 13h</p>
            <p className="mb-2"><strong>Data:</strong> 23/03/2024</p>
            <p className="mb-2"><strong>Horário:</strong> 8h às 13h</p>
            <p className="mb-2"><strong>Mentora:</strong> Dra Eliane Furquim</p>
            <p><strong>Whatsapp:</strong> (15) 981889932</p>
             </div>
             <div className="p-4 flex justify-center items-center">
    <iframe
        className="w-full"
        title="Google Map"
        src="https://maps.google.com/maps?width=100%25&amp;height=6000&amp;hl=en&amp;q=Av.%20Gisele%20Constantino,%201850%20-%20Parque%20Bela%20Vista,%20Votorantim%20-%20SP,%2018110-650+(Conquiste%20seus%20Primeiros%2050%20Mil%20Reais%20como%20Advogado(a))&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        style={{
            height: '300px',
        }}
    >
        <a href="https://www.gps.ie/">gps systems</a>
    </iframe>
</div>
        </div>
    </div>
  );
};

export default MapWithInfoComponent;