import React from 'react';
import Single from '../assets/single.png'
import Double from '../assets/double.png'
import Triple from '../assets/triple.png'
import { Link } from 'react-router-dom';

const Cards = () => {
  return (
    <div className='w-full py-[10rem] px-4 bg-white' id='preço'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-1 gap-8'>
          <div className='w-full shadow-xl bg-gray-100 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300'>
              <img className='w-20 mx-auto mt-[-3rem] bg-transparent' src={Double} alt="/" />
              <h2 className='text-2xl font-bold text-center py-8'>Reserve sua vaga agora e dê o primeiro passo rumo ao sucesso na Advocacia!</h2>
              <p className='text-center text-4xl font-bold'>R$800</p>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>Sessão de mentoria individualizada</p>
                  <p className='py-2 border-b mx-8'>eBook exclusivo</p>
                  <p className='py-2 border-b mx-8'>Café da manhã delicioso</p>
              </div>
              <Link to={'https://forms.gle/ximKauyJGuXVbyWV6'} target="_blank" className='bg-black text-[#ffff] w-[200px] rounded-md font-bold text-lg my-6 mx-auto px-6 py-3'>Inscreva-se Agora</Link>
          </div>
      </div>
    </div>
  );
};

export default Cards;
