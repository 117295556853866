import React from 'react';
import { Link } from 'react-scroll';
import { Link as WatsLink } from 'react-router-dom';

import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-2 gap-8 text-gray-300' id='contato'>
      <div>
        <h1 className='w-full text-3xl font-bold text-[#FFFF]'>MÉTODODEN</h1>
        <p className='py-4'>Conquiste seus Primeiros 50 Mil Reais como Advogado(a). Apenas 50 vagas disponíveis. Garanta a sua agora!</p>
        <WatsLink to={'https://wa.me/+5515981889932'} target="_blank" className='bg-white text-[#000] w-[200px] rounded-md font-bold text-lg my-6 mx-auto px-6 py-3'>Fale Conosco</WatsLink>
      </div>
      <div className='col-span-1 flex justify-between mt-6'>
    <div>
        <ul>
            <li className='py-2 text-sm cursor-pointer'><Link to='hero' spy={true} smooth={true} offset={50} duration={500}>Home</Link></li>
            <li className='py-2 text-sm cursor-pointer'><Link to='sobre' spy={true} smooth={true} offset={50} duration={500}>Sobre</Link></li>
            <li className='py-2 text-sm cursor-pointer'><Link to='informações' spy={true} smooth={true} offset={50} duration={500}>Informações</Link></li>
            <li className='py-2 text-sm cursor-pointer'><Link to='preço' spy={true} smooth={true} offset={50} duration={500}>Valor</Link></li>
            <li className='py-2 text-sm cursor-pointer'><Link to='contato' spy={true} smooth={true} offset={50} duration={500}>Contato</Link></li>
        </ul>
    </div>
      </div>
    </div>
  );
};

export default Footer;
