import React from 'react';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';

const Newsletter = () => {

  return (
    <div className='w-full py-16 text-white px-4' id='inscrição'>
      <div className='max-w-[1240px] mx-auto grid lg:grid-cols-1'>
        <div className='lg:col-span-2 my-4'>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>
          Instruções para garantir sua vaga
          </h1>
          <p>Preencha o formulário abaixo para garantir sua participação neste evento exclusivo.</p>
          <ol className="list-decimal mt-4 ml-8 text-lg">
            <li>Após preencher o formulário, você será adicionado em nosso grupo exclusivo no WhatsApp.</li>
            <li>No grupo do WhatsApp, você receberá todas as informações necessárias sobre a mentoria.</li>
            <li>Por favor, esteja atento às instruções fornecidas no grupo do WhatsApp para concluir o pagamento e garantir sua participação no evento.</li>
          </ol>
          <Link to={'https://forms.gle/ximKauyJGuXVbyWV6'} target="_blank" className="btn btn-primary bg-[#ffff] text-black mt-8 inline-block px-6 py-3 rounded-md text-lg font-semibold">Preencher Formulário</Link>        
          </div>
     
      </div>
    </div>
  );
};

export default Newsletter;