import React from 'react';
import Analytics from './components/Analytics';
import Cards from './components/Cards';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Newsletter from './components/Newsletter';
import GoogleMaps from './components/GoogleMaps';
import List from './components/List';
import ReactPixel from 'react-facebook-pixel';

const advancedMatching = { em: 'pedrofurquim.dev@gmail.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init('1120527895929552', advancedMatching, options);

ReactPixel.pageView(); // For tracking page view


function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Analytics />
      <List/>
      <GoogleMaps/>
      <Cards />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default App;
