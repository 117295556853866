import React from 'react';
import Laptop from '../assets/laptop.png';
import { Link } from 'react-router-dom';

const Analytics = () => {
  return (
    <div className='w-full bg-white py-16 px-4' id='sobre'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[500px] mx-auto my-4' src={Laptop} alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='text-[#000] font-bold '>SOBRE A MENTORIA</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Descubra o caminho para o sucesso financeiro na advocacia.</h1>
          <p>
          Você é advogado(a) e deseja alcançar um novo patamar financeiro em sua carreira? Este evento é para você! 
          Junte-se a Dra. Eliane Furquim e descubra estratégias comprovadas para conquistar seus primeiros 50 mil reais como advogado(a).
          </p>
          <Link to={'https://forms.gle/ximKauyJGuXVbyWV6'} target="_blank" className='bg-black text-[#ffff] w-[200px] text-center rounded-md font-semibold text-xl my-6 mx-auto md:mx-0 py-3'>Garanta sua vaga</Link>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
