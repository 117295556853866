import React from 'react';
import { Link } from 'react-router-dom';
import { ReactTyped  } from 'react-typed';

const Hero = () => {
  return (
    <div className='text-white' id='hero'>
      <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <p className='text-[#ffff] font-bold p-2 sm:text-md md:text-xl lg:md:text-2xl'>
        23 de março, Sábado | Iguatemi Business, Sorocaba
        </p>
        <h1 className='md:text-7xl sm:text-6xl text-3xl py-4 px-4 font-bold md:py-6'>
        Conquiste seus Primeiros 50 Mil Reais como Advogado(a)
        </h1>
        <div className='flex justify-center items-center p-4'>
          <ReactTyped
          className='md:text-2xl sm:text-4xl text-xl font-bold md:pl-4 pl-2'
            strings={['Explore nichos lucrativos no mercado jurídico', 'Alavanque sua carreira', 'Eleve seus honorários', 'Aproveite ao máximo cada caso e cliente']}
            typeSpeed={50}
            backSpeed={20}
            loop
          />
        </div>
        <p className='md:text-2xl text-xl font-bold text-gray-500 p-4'>Apenas 50 vagas disponíveis. Reserve a sua agora!</p>
        <Link to={'https://forms.gle/ximKauyJGuXVbyWV6'} target="_blank" className='bg-[#ffff] w-[200px] rounded-md font-semibold text-xl my-6 mx-auto py-3 text-black'>Inscreva-se agora</Link>
      </div>
    </div>
  );
};

export default Hero;
